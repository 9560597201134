<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="card card-custom">
        <div class="card-header">
            <div class="card-title">
                        <span class="card-icon">
                            <i class="fa fa-tachometer-alt text-aqua"></i>
                        </span>
                <h3 class="card-label text-uppercase">Profile Dashboard</h3>
            </div>
        </div>
        <div class="card-body" v-if="ticketSummary != null">
            <table class="table table-bordered mb-10">
                <thead>
                    <tr>
                        <th class="text-center text-uppercase">Total PURCHASED</th>
                        <th class="text-center text-uppercase">Total Booked</th>
                        <th class="text-center text-uppercase">Total Cancelled</th>
                        <th class="text-center text-uppercase">Total Expired</th>
                        <th class="text-center text-uppercase">Total Tickets</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center text-uppercase">{{ticketSummary.issued}}</td>
                        <td class="text-center text-uppercase">{{ticketSummary.booked}}</td>
                        <td class="text-center text-uppercase">{{ticketSummary.cancelled}}</td>
                        <td class="text-center text-uppercase">{{ticketSummary.expired}}</td>
                        <td class="text-center text-uppercase">{{ticketSummary.total}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div class="col-md-12">
                    <h3>RECENTLY BOOKED TICKET WAITING FOR PAYMENT</h3>
                    <template>
                        <div class="">
                            <v-data-table
                                    dense
                                    :headers="getCompanyInfo.id==1 ? headers2 : headers"
                                    :items="tickets"
                                    :page.sync="page"
                                    :loading="loading"
                                    :items-per-page="itemsPerPage"
                                    class="elevation-1 table-bordered table-striped"
                                    :footer-props="{
                          showFirstLastPage: true,
                          firstIcon: 'fa fa-angle-double-left',
                          lastIcon: 'fa fa-angle-double-right',
                          prevIcon: 'fa fa-angle-left',
                          nextIcon: 'fa fa-angle-right',
                          itemsPerPageText: 'Ticket per page: '
                        }"
                            >
                                <template v-if="getCompanyInfo.id==1" v-slot:item.paribahan="{item}">
                                  <div>{{item.company_name}}</div>
                                </template>
                                <template  v-slot:item.info="{ item }">
                                    <div class="text-left mb-1">
                                        <span>{{item.boarding_station}} -> {{item.dropping_station}}</span>
                                    </div>
                                    <b-badge class="w-100 mb-1" variant="danger">
                                        {{ item.coach_number}}
                                    </b-badge>
                                    <b-badge v-if="item.vehicle_type_id==1" class="w-100 mb-1" variant="success">
                                        {{ item.vehicle_type_name}}
                                    </b-badge>
                                    <b-badge v-if="item.vehicle_type_id==2" class="w-100 mb-1" variant="warning">
                                        {{ item.vehicle_type_name}}
                                    </b-badge>
                                </template>
                                <template v-slot:item.passenger="{item}">
                                    <div>{{item.passenger_name}}</div>
                                    <div class="text-black-50">{{item.passenger_mobile}}</div>
                                </template>
                                <template v-slot:item.seats="{item}">
                                    <div>{{item.seat_numbers}}</div>
                                </template>
                                <template v-slot:item.fare="{item}">
                                    <div>{{item.grand_total}}</div>
                                </template>
                                <template v-slot:item.booking="{ item }">
                                    {{item.booked_at ? moment(item.booked_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A') : moment(item.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}
                                </template>
                                <template v-slot:item.journey="{ item }">
                                    <span class="text-success">{{moment(item.boarding_counter_time,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</span>
                                </template>
                                <template v-slot:item.status="{ item }" class="text-center">
                                    <b-badge class="text-capitalize" v-if="item.status=='booked'" pill variant="warning">{{ item.status}}</b-badge>
                                    <b-badge class="text-capitalize" v-if="item.status=='issued'" pill variant="success">{{ item.status}}</b-badge>
                                    <b-badge class="text-capitalize" v-if="item.status=='cancelled' && item.issued_at==null" pill variant="danger">Expired</b-badge>
                                    <b-badge class="text-capitalize" v-if="item.status=='cancelled' && item.issued_at!=null" pill variant="info">{{ item.status}}</b-badge>
                                    <br/>
                                    <small v-if="item.status=='booked'">{{moment(item.booked_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</small>
                                    <small v-if="item.status=='issued'">{{moment(item.issued_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</small>
                                    <small v-if="item.status=='cancelled'">{{moment(item.cancelled_at,"yyyy-MM-DD hh:mm:ss").format('D MMM yyyy hh:mm A')}}</small>
                                </template>
                                <template v-slot:item.view="{ item }">
                                    <router-link :to="{name:'ticket-details',params:{id:item.id}}"> <v-chip class="bg-warning" style="cursor: pointer;">View</v-chip></router-link>
                                </template>
                            </v-data-table>
                        </div>
                    </template>
                </div>
            </div>
        </div>

    </div>
</template>
<style lang="scss">
    tbody {
        tr:hover {
            background-color: #e0efff !important;
        }
    }
    .v-data-footer__select {
        margin-right: 30px !important;
    }
</style>

<script>
    import profileService from "@/core/services/models/profile.service";
    import {mapGetters} from "vuex";

    export default {
        name: "ProfileDashboard",
        data(){
            return{
                ticketSummary: null,
                page: 1,
                pageCount: 0,
                itemsPerPage: 5,
                loading:true,
                headers: [
                    {text : '#', value : 'sl', value:'id'},
                    {text : 'Coach Info', value : 'info'},
                    {text : 'Passenger', value : 'passenger'},
                    {text : 'Seats', value : 'seats'},
                    {text : 'Fare', value : 'fare'},
                    {text : 'Book/Issue Time', value : 'booking'},
                    {text : 'Journey Time', value : 'journey'},
                    {text : 'Status', value : 'status'},
                    {text : 'View', value : 'view'},
                ],
                headers2: [
                  {text : '#', value : 'sl', value:'id'},
                  {text : 'Paribahan', value : 'paribahan'},
                  {text : 'Coach Info', value : 'info'},
                  {text : 'Passenger', value : 'passenger'},
                  {text : 'Seats', value : 'seats'},
                  {text : 'Fare', value : 'fare'},
                  {text : 'Book/Issue Time', value : 'booking'},
                  {text : 'Journey Time', value : 'journey'},
                  {text : 'Status', value : 'status'},
                  {text : 'View', value : 'view'},
                ],
                tickets: [],
            }
        },
        computed:{
          ...mapGetters(['getCompanyInfo']),
        },
        methods:{
            getTicketList(page,perPage){
                //console.log(`page ${page} perPage ${perPage}`);
                this.loading = true;
                this.tickets = [];
                profileService.getTicketList({page:page,perPage:perPage,status:'booked'}, data =>{
                    this.page       = page;
                    this.tickets    = data.data;
                    this.pageCount  = data.last_page;
                    this.loading    = false;
                });
            }
        },
        mounted(){
            profileService.getTicketSummary(d =>{
              this.ticketSummary = d.data;
            });
            this.getTicketList(this.page,this.itemsPerPage);
        }
    }
</script>

<style scoped>
  .border-less{
      border: 0px solid white;
  }
</style>
